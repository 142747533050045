.a123 {
  padding-top: 10px;
  width: 100%;
  margin: auto;
}

.adminka {
  /* display: grid;
grid-template-columns: repeat(3,1fr);
gap: 40px; */
}
.piproot {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding-top: 20px;
  border-color: #aaa;
}
.admintabs {
  /* color: black;
border-radius: 5px;
border: 1px solid;  */
}
.atabs {
  display: flex;
  margin: 0;
  padding: 0;
  gap: 35px;
}
.video-prev {
  width: 100%;
  height: auto;
}
.atabs .react-tabs__tab--selected i {
  color: #ffb400;
  border: none;
}
.atabs .react-tabs__tab--selected i::before {
}
.atabs i::before {
}
.pitem {
  position: relative;
  cursor: pointer;
}
.ant-btn {
  border: none;
  box-shadow: none;
  display: flex;
  color: grey;
  right: 0px;
  align-items: center;
}
.ant-btn:hover,
.ant-btn:focus {
  color: #ffb400;
  border-color: #ffb400;
  background: #fff;
}
.pitem video {
  pointer-events: none;
}
.editbtna {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: white;
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 10px;
}
.inpad {
  display: flex;
  color: black;
  align-items: center;
  gap: 20px;
}
.inpad input {
  margin-bottom: 0px;
}
.atabs .react-tabs__tab--selected {
  border: none;
  border-radius: 0;
  outline: none;
  /* border-bottom: 1px solid #ffb400; */
}
.cardAdminimg {
  width: 100%;
  height: 200px;
  margin-bottom: 10px;
  overflow: hidden;
}
.blueP {
  color: grey;
  font-size: 22px;
}
.cardAdminimg img {
  /* width: 70%;
  height: auto; */
}
.css-b62m3t-container {
  width: 100%;
  color: black;
  margin-top: 20px;
}
.adTags {
  grid-column: 1/3;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}
.atg {
  padding: 5px 10px;
  color: #3b3a3a;
  font-size: 14px;
  border-radius: 10px;
  border: 1px solid #ffb400;
}

.pitem {
  width: 100%;
  margin: 0 auto;
  border: 1px solid #ccc;
  /* padding: 20px 10px; */
  border-radius: 10px;
  text-align: center;
  color: #3b3a3a;
  font-family: "Open Sans", sans-serif;
}
.lad {
  font-family: "Open Sans", sans-serif;
  font-size: 28px;
  color: #666;
}
.pitem a {
  display: block;
  padding-top: 20px;
}
.pitem p {
  padding-top: 10px;
}
.links {
  display: flex;
  /* flex-direction: column; */

  padding-top: 0;
}
.mada {
}
.mainadm {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.adbtnss {
  background-color: transparent;
  /* border-radius: 15px; */
  /* padding: 5px 15px; */
  margin: 0;
  padding: 0;
  border: none;
  color: #666;
  font-size: 28px;
  font-family: "Open Sans", sans-serif;
  transition: 0.3s;
  order: 3;
}
/* .adbtnss:hover {
  background-color: #ffb400;
} */
.headerAdmin {
  padding: 20px 0px;
  display: flex;
  justify-content: space-between;
}
.links a {
  margin-right: 30px;
  color: #3b3a3a;
}
.return {
  padding-top: 0;
  /* margin-bottom: 5px; */
}

.bebra {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.modal04ka {
  margin-bottom: 5px;
}
.pointer {
  cursor: pointer;
}

.white {
  background-color: white;
}

.asd {
  padding-top: 10px;
  width: 98%;
  margin: auto;
}

.blue {
  color: #666;
  font-size: 26px;
  margin: 0;
}
.linksa {
  color: #3b3a3a;
  text-align: center;
}
.addAdmin {
  position: relative;
  border: none;
  /* width: 22px;
  height: 100%; */
  background-color: transparent;
  /* margin-left: 10px; */
  transition: 0.3s;
  color: #666;
  display: flex;
  align-items: center;
}
.addAdmin svg {
  width: 20px;
  height: 20px;
  transition: 0.3s;
}
.addAdmin:hover svg {
  fill: #ffb400;
}

.adtitlebtn {
  display: flex;
  align-items: center;
}
.content {
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}
.adDesk {
  margin-top: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  font-size: 22px;
}
.adDesk span {
  word-break: break-all;
  color: black;
  font-size: 18px;
  margin: 0;
}
.pimgAdmin {
  width: 100%;
  display: block;
  margin: 0 auto;

  grid-column: 1/3;
}

.pimgAdmin_img {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 150px;
  gap: 20px;
}
.pimgAdmin_img img {
  padding: 5px;
  min-height: auto !important;
  width: 100%;
  height: 100%;
  min-height: auto;
  border: 1px solid #3b3a3a;
  border-radius: 10px;
}
.pimgAdmin_img figure {
  width: 100%;
  height: auto;
  overflow: hidden;
}
.adDesk p {
  font-size: 22px;
}
.post-thumb div {
  width: 100%;
  height: 14rem;
  max-height: 222px;
  /* border-radius: 9px; */
  border-top-right-radius: 9px;
  border-top-left-radius: 9px;
}
.err {
  position: relative;
}
.err::after {
  position: absolute;
  top: 41px;
  left: 16px;
  width: 100%;
  color: red;
  content: "Не заполненно поле";
  font-size: 10px;
}
.errfile {
  position: relative;
  /* color: red; */
}
.errfile::after {
  position: absolute;
  top: 41px;
  left: 16px;
  width: 100%;
  color: red;
  content: "Не заполненно поле";
  font-size: 10px;
}
.layout1 {
  order: -1;
}
/* .custom-overlay {
  position: absolute;
  display: flex;
  min-height: 300vh;
  justify-content: center;
  align-items: baseline;
}
.custom-modal {
  max-width: 700px;
  top: 100px;

  position: relative;
  left: 0;
  transform: translate(0%, 0%);
} */
.gr-3 {
  grid-column: 1/3;
}
.uploadtwoicons {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;
  padding-bottom: 20px;
  padding-top: 0px;
  margin-top: -20px;
}
.ant-upload-select-picture {
  min-width: 250px;
}
.dflexmob {
  display: flex;
  margin-top: 10px;
}
.twoIcons {
  color: #666;
  display: flex;
  align-items: center;
}
.ql-stroke {
  stroke: #666 !important;
}
.ql-editor {
  color: #666;
}
.twoIcons input {
  width: 50px;
  margin-left: 10px;
  margin-bottom: 0;
}

.ant-upload-list-item-list-type-picture {
  border: none !important;
}
.ql-toolbar.ql-snow {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.ql-container.ql-snow {
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
}
.uplant {
  display: flex;
  /* justify-content: center; */
  /* width: 50%; */
}
.dflexmob {
  /* width: 50%; */
  /* justify-content: center; */
}
.uplant span {
  color: #666;
}
.hv {
  transition: 0.3s;
}
.hv:hover {
  color: #ffb400;
}
.removepst {
  position: absolute;
  bottom: 20px;
  left: 20px;
  border: none;
  background-color: transparent;
  width: 50px;
  height: 50px;
}
.removepst svg {
  fill: #666;
  width: 30px;
  height: 30px;
  transition: 0.3s;
}
.removepst svg:hover {
  fill: #ffb400;
}
.admbtns {
  margin-top: 30px;
}
.admbtns:hover span {
  color: #fff !important;
}
@media (max-width: 700px) {
  .admhdr {
    flex-wrap: wrap;
    padding-top: 20px;
    align-items: center;
  }
  .uplant {
    width: 100%;
    order: 1;
  }
  .form-group .uplant .ant-upload-select-picture {
    display: flex;
    justify-content: start;
  }
  .form-group .ant-btn {
    padding-left: 0px;
  }
  .uplant .ant-upload-select-picture {
    display: flex;
    justify-content: center;
  }
  .adbtnss {
    padding: 0px;
    margin: 0;
  }
  .addAdmin {
    margin: 0;
  }
  .return {
    width: 30px;
  }
  .adbtnss {
    width: 30px;
  }
  .dflexmob {
    width: 100%;
    justify-content: center;
    margin-bottom: 10px;
  }
  .atabs {
    gap: 0px;
    flex-wrap: wrap;
    justify-content: center;
    order: 1;
  }
  /* .custom-overlay {
    min-height: 300vh;
  } */
  .piproot {
    grid-template-columns: repeat(1, 1fr);
  }
  /* .custom-overlay {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: baseline;
  } */
  .createApost {
    min-width: 100%;
  }
  .editbtn {
    flex-wrap: wrap;
    justify-content: center;
    gap: 0px;
  }
  .admin-panel {
    width: 90%;
  }
  /* .custom-modal {
    width: 90%;
    top: 40px;
    position: relative;
    left: 0;
    transform: translate(0%, 0%);
  } */
  .adDesk {
    gap: 5px;
  }
  .uploadtwoicons {
    width: 100%;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: end;
  }
  .card--expanded {
    grid-column: span 1;
    grid-row: span 2;
  }
  .card__title {
    opacity: 1;
  }
  .card::after {
    opacity: 1;
  }
}

.wrpgl {
  position: relative;
}
.wrpgl::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.imgbg {
  transition: 0.6s;
}
.imgbg:hover {
  transform: scale(1.2);
  -webkit-transform: scale(1.2);
}
.pb30px {
  padding-top: 40px !important;
}
/* .fa-spinner {
  animation: spin 2s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} */

/*----FontAwesome-------*/
@import url("../fonts/font-awesome/css/font-awesome.min.css");
@import "bootstrap/dist/css/bootstrap.css";

// Style Scss
@import "main/modal";
@import "main/portfolio-modal";
@import "main/portfolio";
@import "main/404";
@import "main/preview";
@import "main/circle";
@import "main/slick";
@import "main/style";

// skin
@import "main/skins/yellow";

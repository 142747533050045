.modal_portfolio {
  overflow-y: auto;
}

.modal_portfolio .modal__content {
  top: 15%;
  left: 25%;
  transform: translate(0, 0);
}
.preview-link {
   color: var(--main-primary-color);
   text-decoration: underline;
}

.preview-link:hover {
   color: var(--main-primary-color);
  }


.admin-panel {
  display: flex;
  align-items: center;
  flex-direction: column;
  /* padding-top: 100px; */
  width: 90%;
  margin: 0 auto;
  padding-bottom: 40px;
}
.subbtn {
  width: 300px;
  background-color: transparent;
  cursor: pointer;
  border-radius: 15px;
  padding: 10px 15px;
  border: 1px solid var(--main-primary-color);
  color: #666;
  margin-top: 20px;
  transition: 0.3s;
  font-size: 15px;
  font-weight: 600;
}
.quill {
  width: 100%;
}
.admtab {
  display: flex;
  align-items: center;
}
.entry-header-admin {
  padding: 10px 20px;
}
.entry-header-admin h3 {
  font-size: 20px;
  font-weight: 600;
  color: #454545;
}
.admhdr {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0px;
}
.subbtn:hover {
  color: white;
  background-color: #ffb400;
}
input[type="file" i] {
  color: black;
}
input[type="file"] input {
  background-color: #ffb400;
  color: white;
}
.createApost {
  min-width: 700px;
}
.admin-panel h1 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.admin-panel__input {
  margin-bottom: 20px;
  padding: 7px 15px;
  width: 100%;
  color: black;
  border-radius: 30px;
  background-color: #fff;
  border: 1px solid #ddd;
  color: #666;
}

.admin-panel__input23 {
  margin-bottom: 20px;
  width: 100%;
  height: 200px;
  padding: 20px;
  border-radius: 30px;
  background-color: #fff;
  border: 1px solid #ddd;
  color: #666;
}
.ant-upload-list-item-image {
  border-radius: 10px;
  /* transform: scale(2); */
}
.anticon-file {
  /* border: 1px solid #666;
  border-radius: 10px;
  padding: 8px; */
}
.admupl .upvid {
  margin-left: 0px;
}
.ant-upload-list-item-thumbnail {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 0px;
  width: 50px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.ant-upload-list-item-thumbnail {
  width: 100%;
  height: auto;
}
.ant-upload-list-item-file {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-upload-span {
  display: flex;
  align-items: center;
}
.anticon-file svg path:nth-child(1) {
  fill: transparent;
}
.anticon-file svg path {
  fill: #666;
}
.ant-upload-list-item-info {
  width: max-content;
}
.blog-excerpt .htmlparser {
  margin-top: 0px;
  margin-bottom: 10px;
  font-weight: normal;
  /* word-break: break-all; */
}
.htmlparser {
  margin-top: 30px;
  font-weight: normal;
}
.htmlparser p {
  color: #666 !important;
  margin: 0 !important;
  padding: 0 !important;
}
.htmlparser span {
  color: #666 !important;
}
.admin-panel {
  color: black;
}
.dv {
  width: 100%;
}
.mb10 {
  padding-bottom: 10px;
}
.mb20 {
  padding-bottom: 20px;
}

.ant-upload-list-item-name {
  white-space: break-spaces;
}
.return {
  color: blue;
  text-decoration: none;
  padding-top: 20px;
}
.sad {
  transition: 0.3s;
  background-color: #ffb400;
}
.dinp {
  transition: 0.3s;
}
.dinp:hover {
  color: #ffb400;
}

.return:hover {
  color: blue;
}

.white {
  background-color: white;
  min-height: 100vh;
}

.blue {
  color: blue;
}
.admmult {
  width: 100%;
  margin-top: 20px;
}
.editbtn {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
.slick-dots {
  background-color: transparent;
}
.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
  grid-template-rows: minmax(10rem, 1fr);
  grid-auto-rows: 1fr;
  grid-gap: 16px;
  grid-auto-flow: dense;
}

.grid-template-columns {
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
}

.grid-gap {
  grid-gap: 2.5rem;
}

/* styling */

.card--expanded {
  border: none;
  grid-column: 1 / -1;
  /* grid-column: max;*/
  grid-row: span 4;
}

.card {
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  /* padding: 1rem; */
}
.card img {
  border-radius: 15px;
}
.card > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card__avatar {
  height: 6rem;
  width: 6rem;
  border-radius: 100%;
  /* background-color: hsla(0, 0%, 0%, 0.2); */
  margin-bottom: 1rem;
}

.card__title {
  opacity: 0;
  z-index: 2;
  padding: 0px 10px;
  position: relative;
  font-size: 14px;
  color: var(--main-primary-color);
  text-transform: uppercase;
  text-align: center;
  transition: 0.5s;
}
.card:hover .card__title {
  opacity: 1;
}
.card:hover::after {
  opacity: 1;
}

.card::after {
  position: absolute;
  content: "";
  opacity: 0;
  width: 100%;
  border-radius: 10px;
  height: 100%;
  transition: 0.5s;
  background-color: rgba(0, 0, 0, 0.75);
}
.card--expanded {
  color: transparent;
}
.card--expanded::after {
  opacity: 0;
  background-color: transparent;
}
.card--expanded .card__title {
  color: transparent;
}
.card__description {
  height: 2rem;
  width: 8rem;
  /* background-color: hsla(0, 0%, 0%, 0.2); */
}

.card--1 {
  background-color: transparent;
  border-radius: 15px;
}

body {
  background-color: #191919;
  color: lightgray;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Helvetica Neue", Arial, sans-serif;
  font-size: 1.1rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.pt-4 {
  margin-top: 1rem;
}

.p-4 {
  padding: 1rem;
}

button {
  padding: 0.75rem;
  margin-right: 0.75rem;
  background-color: #191919;
  color: lightgray;
  border: 1px solid lightgray;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: transparent;
}
button:focus {
  box-shadow: 0 0 0 3px #7396e4;
  outline: none;
}

a {
  font-weight: bold;
  /* color: #7396e4; */
}

code {
  color: #7396e4;
}
.gcard {
  width: 220px;
  height: 220px;
  transform-origin: 0px 0px;
  background-size: cover;
  background-position: center;
  border-radius: 15px;
  /* margin-bottom: 30px; */
  /* flex: 2 2 10%; */
}
.flex-galery {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 20px;
}
.sliderCard img {
  border-radius: 10px;
}
.andtpickerFormDate .admin-panel__input {
  padding: 9px 15px !important;
}

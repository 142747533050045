.sent {
  margin-left: 35px;
  display: flex;
}
.sentb {
  color: white;
}
.contactform {
  position: relative;
}

.bebra2 {
  position: absolute;
  top: -1000px;
}
.bebra2:hover {
  color: yellow;
}
.fa.fa-plus-circle {
  margin-left: 35px;
}
.vodr video {
  border-radius: 10px;
}
.form-group .vodr video {
  border-radius: 5px;
}
.ssd {
  min-width: 215px;
}
.gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  transition: 1s linear;
  gap: 20px;
  /* transition: all 0.1s ; */
}
.form-group .vodr {
  width: 100%;
  max-height: 48px;
  display: flex;
}
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
.ant-upload-list-picture .ant-upload-list-item-thumbnail {
  /* height: auto !important; */
  border: none;
}
.ant-upload-list-picture-card .ant-upload-list-item-uploading,
.ant-upload-list-picture .ant-upload-list-item-uploading {
  width: max-content;
}
.ant-upload-list-picture-card
  .ant-upload-list-item-uploading
  .ant-upload-list-item-name,
.ant-upload-list-picture
  .ant-upload-list-item-uploading
  .ant-upload-list-item-name {
  margin-bottom: 0px !important;
}
.bgUpload {
  background: no-repeat;
  background-size: contain;
  background-position: center;
  width: 48px;
  height: 48px;
}
.ant-upload-list-picture .ant-upload-span > *,
.ant-upload-list-text .ant-upload-span > * {
  height: auto;
}
.ant-upload-list-item {
  height: 48px !important;
  width: max-content !important;
  color: gray;
  font-size: 12px;
}
.upvid {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 48px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;

  -khtml-border-radius: 5px;
}
.ant-upload-list-item-progress {
  padding-left: 56px !important;
}
strong {
  color: #666 !important;
}
em {
  color: #666 !important;
}

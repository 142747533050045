.row {
	font-size: 16px;
}

.modal_portfolio {
  overflow-y: auto;
}

.modal_portfolio .modal__content {
  top: 15%;
  left: 25%;
  transform: translate(0, 0);
}
@media (max-width: 720px) {
  .modal_portfolio .modal__content {
    top: 15%;
    left: 10% !important;
    transform: translate(0, 0);
  }
}
.slick-dots {
  bottom: 7px;
  background: rgba(0, 0, 0, 0.4);
  padding: 10px;
  @media screen and (max-width: 767px) {
    padding: 5px;
  }
  li {
    margin: 0 3px;
    button {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      position: relative;

      &::before {
        content: "";
        font-size: 0;
        opacity: 1;
        border: 2px solid var(--main-primary-color);
        width: 100%;
        height: 100%;
        border-radius: 50%;
        transition: 0.3s ease-in-out;
      }
      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        transform: scale(0);
        background: var(--main-primary-color);
        transition: 0.3s ease-in-out;
        border-radius: inherit;
        z-index: 5;
      }
    }
    &.slick-active {
      button {
        &::after {
          transform: scale(0.6);
        }
      }
    }
  }
}

.slick-arrow {
  display: none !important;
}

.img {
  display: block;
  position: relative;
}
.body {
}
.fade {
}
.cli {
  position: relative;
  /* transition: order 0.7s linear; */
  display: block;
  /* max-width: 180px; */
  border-radius: 0.6rem;
  cursor: pointer;
}
.cli img {
  border-radius: 0.6rem;
}
.cli h3 {
  position: absolute;
  top: 50%;
  left: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
  background-color: #0000008a;
  opacity: 0;
  padding: 0px 10px;
  visibility: hidden;
  transition: all 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: var(--main-primary-color);
  text-transform: uppercase;
  border-radius: 0.6rem;
  /* transition: 0.2s; */
}
.cli:hover h3 {
  opacity: 1;
  font-size: 14px;
  visibility: visible;
}
.cl h3 {
  background-color: transparent;
  opacity: 0 !important;
  visibility: hidden !important;
}
.cl {
  color: transparent;
  /* order: -1; */
  /* transition: order 1s; */
  /* max-width: 100%; */
}

.nocl {
  display: block;
}
@media (max-width: 700px) {
  .cli h3 {
    opacity: 1;
    font-size: 14px;
    visibility: visible;
  }


.a {
   color: var(--main-primary-color);
   text-decoration: underline;
   font-size: 18px;
   font-weight: 600;
}
}

// Modal css\

.custom-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 99;
  &.dark {
    background-color: rgba(0, 0, 0, 0.35);
  }
  &.light {
    background-color: rgba(0, 0, 0, 0.15);
  }
}
.blog-post article {
  padding-bottom: 0px;
  width: 100%;
    height: auto;
    // padding-bottom: 15px;
}
.element::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 0;
    height: 0;
}
// #root::-webkit-scrollbar { width: 0; } 
.custom-modal .box_inner::-webkit-scrollbar { width: 0;opacity: 0; }
.custom-modal .box_inner { -ms-overflow-style: none; }
// .light::-webkit-scrollbar { width: 0; }
.custom-modal.dark.light .box_inner.about::-webkit-scrollbar { width: 0;opacity: 0; } 
.custom-modal .box_inner { overflow: -moz-scrollbars-none; }
.custom-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none;
  background: #fff;
  overflow: initial;
  border-radius: 1rem;
  outline: none;
  .box_inner {
    height: 80vh;
    width: 750px;
    overflow-y: auto;

    @media (max-width: 1400px) {
      width: 900px;
    }
    @media (max-width: 992px) {
      width: 700px;
    }
    // @media (max-width: 768px) {
    //   width: 560px;
    // }
    @media (max-width: 599px) {
      width: 460px;
    }
    @media (max-width: 490px) {
      width: 360px;
    }
    @media (max-width: 420px) {
      width: 320px;
      height: 80vh;
    }
    &.blog-post {
      padding: 20px;
      @media (max-width: 767px) {
        padding: 15px;
      }
      .title-section {
        padding: 20px;
        @media (max-width: 767px) {
          padding: 0px 0px;
        }
      }
      .title-section .title-bg {
        margin-left: -15px;
		margin-top: -5px;
}
      .title-section .title-bg1 {
        margin-left: -15px;
		margin-top: 0px;
      }
    }
  }
}
.custom-modal .title-section {
  padding: 20px;
  @media (max-width: 767px) {
    
    padding: 40px 0px !important;
    margin-bottom: 40px !important;
  }
}

.ReactModal__Overlay {
  opacity: 0;
  transition: all 0.3s ease;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ant-btn:hover,
.ant-btn:focus {
  color: #ffb400;
  border-color: #ffb400;
  background: #fff;
}

.close-modal {
  background: transparent;
  border: none;
  display: flex;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  position: absolute;
  z-index: 4;
  right: -38px;
  top: -23px;
  @media screen and (max-width: 1199px) {
    right: -38px;
    top: -23px;
  }
  @media screen and (max-width: 575px) {
    right: -38px;
    top: -23px;
  }
  img {
    width: 45px;
    height: 45px;
    cursor: pointer;
    position: absolute;
    filter: invert(1);
    transition: 0.3s;
    @media screen and (max-width: 575px) {
      width: 30px;
      height: 30px;
    }
  }
  &:hover {
    img {
      filter: invert(0.8);
    }
  }
}

.custom-modal.dark .box_inner::-webkit-scrollbar {
  width: 0px; /* width of the entire scrollbar */
}

.custom-modal.dark .box_inner::-webkit-scrollbar-track {
  background: #333; /* color of the tracking area */
}

.custom-modal.dark .box_inner::-webkit-scrollbar-thumb {
  background-color: #555; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 0px solid #333; /* creates padding around scroll thumb */
}
body.light .custom-modal.dark .box_inner::-webkit-scrollbar-track {
  background: #fff; /* color of the tracking area */
}

body.light .custom-modal.dark .box_inner::-webkit-scrollbar-thumb {
  background-color: #bbb; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 0px solid #fff; /* creates padding around scroll thumb */
}
.custom-modal.dark.light .box_inner.about {
  border: 2px solid #fff;
}
.custom-modal.dark {
  background: #252525;
  &.hero {
    background: #111;
  }
  &.light {
    background: #fff;
  }
  .box_inner {
    &.about {
      border-radius: 1rem;
      @media (min-width: 1600px) {
        width: 1190px;
      }
      @media (max-width: 1600px) {
        width: 1070px;
      }
      @media (max-width: 1400px) {
        width: 900px;
      }
      @media (max-width: 992px) {
        width: 700px;
      }
      @media (max-width: 599px) {
        width: 460px;
      }
      @media (max-width: 490px) {
        width: 360px;
      }
      @media (max-width: 420px) {
        width: 320px;
      }
      border: 2px solid #222;
    }
    &.portfolio {
      @media (min-width: 1600px) {
        width: 660px;
        height: auto;
      }
      @media (max-width: 1499px) {
        height: auto;
      }
    }
  }
}
